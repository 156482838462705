<template>
  <div id="strategic-partners" class="container strategic-partners pb-5">
    <div class="row p-4">
      <div class="col-12">
        <h2 class="text-center pt-5">
          Interested in working with one of our strategic partners?
        </h2>
        <h5 class="text-center pt-4 pb-5">
          EMAIL YOUR INQUIRY TO
          <a
            href="mailti:info@kravestuios.com"
            class="text-black-50"
            target="_blank"
            >INFO@KRAVESTUDIOS.COM</a
          >
        </h5>
      </div>
    </div>
    <div class="row p-5">
      <div
        class="col-12 offset-0 col-sm-10 offset-sm-1 col-md-6 offset-md-0 col-lg-4 offset-lg-0 text-center p-5"
      >
        <div class="row">
          <div class="col-12 border-bottom">
            <h4 class="p-3">Fashion Production</h4>
          </div>
          <div class="partners-logo col-12">
            <a
              href="https://www.faktory.nyc/"
              target="_blank"
              class="border-0 margin-0"
            >
              <img
                src="/assets/img/factory-nyc-logo.jpg"
                alt=""
                class="img-fluid partners-logo-img"
              />
            </a>
          </div>
        </div>
      </div>
      <div
        class="col-12 offset-0 col-sm-10 offset-sm-1 col-md- offset-md-06 col-lg-4 offset-lg-0 text-center p-5"
      >
        <div class="row">
          <div class="col-12 border-bottom">
            <h4 class="p-3">Travel</h4>
          </div>
          <div class="partners-logo col-12">
            <a
              href="https://www.luxwt.com/"
              target="_blank"
              class="border-0 margin-0"
            >
              <img
                src="/assets/img/luxury-world-traveler-logo.jpg"
                alt=""
                class="img-fluid partners-logo-img"
              />
            </a>
          </div>
        </div>
      </div>
      <div
        class="col-12 offset-0 col-sm-10 offset-sm-1 col-md-6 offset-md-0 col-lg-4 offset-lg-0 text-center p-5"
      >
        <div class="row">
          <div class="col-12 border-bottom">
            <h4 class="p-3">Locations</h4>
          </div>
          <div class="partners-logo col-12">
            <a
              href="https://luxwtrealty.com/"
              target="_blank"
              class="border-0 margin-0"
            >
              <img
                src="/assets/img/luxwt-reality-logo.jpg"
                alt=""
                class="img-fluid partners-logo-img"
              />
            </a>
          </div>
        </div>
      </div>
      <div
        class="col-12 offset-0 col-sm-10 offset-sm-1 col-md-6 offset-md-0 col-lg-4 offset-lg-2 text-center p-5"
      >
        <div class="row">
          <div class="col-12 border-bottom">
            <h4 class="p-3">Experiential Marketing</h4>
          </div>
          <div class="partners-logo col-12">
            <a
              href="https://www.martinvanegasagency.com/"
              target="_blank"
              class="border-0 margin-0"
            >
              <img
                src="/assets/img/martin-and-vanegas-agency-logo.jpg"
                alt=""
                class="img-fluid partners-logo-img"
              />
            </a>
          </div>
        </div>
      </div>
      <div
        class="col-12 offset-0 col-sm-10 offset-sm-1 col-md-6 offset-md-0 col-lg-4 offset-lg-0 text-center p-5"
      >
        <div class="row">
          <div class="col-12 border-bottom">
            <h4 class="p-3">Environmental Impact</h4>
          </div>
          <div class="partners-logo col-12">
            <a
              href="https://oceanic.global/"
              target="_blank"
              class="border-0 margin-0"
            >
              <img
                src="/assets/img/oceanic-global-logo.jpg"
                alt=""
                class="img-fluid partners-logo-img"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Partners",
  created() {
    this.$store.commit("SET_FOOTER", true);
    this.footer = this.$store.state.footer;
  },
};
</script>

<style scoped>
@import "../css/Partners.scss";
</style>
